import React, {useCallback, useEffect, useState} from 'react';
import {get} from '../../Axios/get';
import {FOOTBALL, NBA, NFL, MLB, CRICKET, NCAA} from '../../Axios/EndPoints';
import {useSelector} from "react-redux";
import GoogleAdManager from "../../Components/Ads/GoogleAdManager";
import moment from "moment-timezone";
import {Button, Card} from 'react-bootstrap';
import Loader from "../../Components/Loader/Loader";
import HomeTopSlider from "./HomeTopSlider";
import {Link} from "react-router-dom";

const NewHomePage = (props) => {
    const {isMobile} = props;
    const [sliderData, setSliderData] = useState([]);

// Separate states for each sport's news
    const [footballNews, setFootballNews] = useState([]);
    const [nbaNews, setNbaNews] = useState([]);
    const [nflNews, setNflNews] = useState([]);
    const [mlbNews, setMlbNews] = useState([]);
    const [cricketNews, setCricketNews] = useState([]);
    const [ncaaNews, setNcaaNews] = useState([]);
    const [womensNews, setWomensNews] = useState([]);  // New state for Women's news

    const [loading, setLoading] = useState(false);

    const sportEndpoints = [
        {endpoint: FOOTBALL.LatestNewsEndPoint, sport: 'football'},
        {endpoint: NBA.NewsEndPoint, sport: 'nba'},
        {endpoint: NFL.NewsEndPoint, sport: 'nfl'},
        {endpoint: MLB.NewsEndPoint, sport: 'mlb'},
        {endpoint: CRICKET.NewsEndPoint, sport: 'cricket'},
        {endpoint: NCAA.NewsEndPoint, sport: 'ncaa'},
        {endpoint: FOOTBALL.LatestNewsEndPoint, sport: 'womens', teamType: 'team_women'}, // Women's news
    ];

    // console.log("latestNews at home page is  >>>>", latestNews);

    const fetchLatestNews = async () => {
        setLoading(true);

        try {
            const tempSliderData = [];
            const tempNewsData = {
                football: [],
                nba: [],
                nfl: [],
                mlb: [],
                cricket: [],
                ncaa: [],
                womens: [],  // Initialize empty array for Women's news
            };

            const requests = sportEndpoints.map(({endpoint, sport, teamType}) => {
                const limit = teamType ? `?limit=9&teamType=${teamType}` : `?limit=9`;
                return get(`${endpoint}${limit}`).then((response) => ({
                    sport,
                    data: response?.data,
                }));
            });

            const responses = await Promise.all(requests);

            // Process each sport's news data
            responses.forEach(({sport, data}) => {
                const newsData = data?.map((newsItem) => ({
                    ...newsItem,
                    sport,
                    is_snack: newsItem?.is_snack,
                    news_id: newsItem?.news_id,
                    slug: newsItem?.slug,
                    news_image: newsItem?.image,
                    news_title: newsItem?.title,
                    news_url: newsItem?.url,
                    news_time: moment.tz(newsItem?.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                    news_date: moment(newsItem?.datetime).format('D MMM YYYY, H:mm'),
                    news_publisher: {
                        publisher_name: newsItem?.publisher.name,
                        publisher_icon: newsItem?.publisher.icon,
                    },
                }));

                // Set the first news item to sliderData
                if (newsData?.length > 0) {
                    tempSliderData.push(newsData[0]);

                    // Add the next 8 items to their respective sport's state
                    const remainingNews = newsData?.slice(1, 9);
                    tempNewsData[sport] = remainingNews;
                }
            });

            // Update states for slider and sport-specific news
            setSliderData(tempSliderData);
            setFootballNews(tempNewsData.football);
            setNbaNews(tempNewsData.nba);
            setNflNews(tempNewsData.nfl);
            setMlbNews(tempNewsData.mlb);
            setCricketNews(tempNewsData.cricket);
            setNcaaNews(tempNewsData.ncaa);
            setWomensNews(tempNewsData.womens);  // Set Women's news state

        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchLatestNews();
    }, []);



    const addDefaultSrc = (ev) => {
        ev.target.src = require(
            '../../assets/images/football-shots-you-need-to-know.jpg');
    };

    const renderLinkHeading = (heading, link) =>{
        return(
            <>
                <div className={`d-flex flex-row align-items-center justify-content-between w-100 ${isMobile ? "px-3":""}`}>
                    <div className={`jost font-weight-600 text-uppercase ${isMobile ?"font-16 text-white mt-2":"font-30 text-black my-3"}`}>{heading}</div>
                    <Link
                        to={link}
                        className={`jost font-weight-600 bg-transparent text-decoration-none ${isMobile ?"font-12 text-white":"font-16  text-black"}`}
                        style={{
                            borderWidth: "0px 0px 2px 0px",
                            borderStyle: "solid",
                            borderColor: "#FF5C01"
                        }}
                    >
                        VIEW ALL
                    </Link>
                </div>
            </>
        )
    }
    const renderSmallCard = (imageHeight, news) => {
        return (
            <>
                <Card
                    className={`col mt-2 border-0 ${isMobile ? "bg-light-black":"bg-white"}`}
                    style={{
                        height: isMobile ?
                            ('105px') :
                            ('290px'),
                        width: isMobile ? 'auto' : 'auto',
                        marginLeft: '10px',
                    }}
                >
                    <Card.Body className={`p-0 m-0 d-flex ${isMobile ? "flex-row w-100" : "flex-column"}`}>
                        <Card.Img
                            variant={"top"}
                            className={'rounded-0'}
                            height={isMobile ? "105px" : imageHeight}
                            // width={isMobile ? 'w-30' : 'auto'}
                            src={news && news?.news_image}
                            alt={news && news?.news_title}
                            onError={addDefaultSrc}
                            style={{
                                objectFit: 'cover',
                                width: isMobile ? '40%' : 'auto',
                            }}
                        />

                        <div style={{width: isMobile ? "60%" : "auto"}}
                             className={`d-flex flex-column py-1 text-start gap-1 ${isMobile ? "px-2 ":""}`}>
                            <div className={"d-flex align-items-center justify-content-between"}>
                                <span
                                    className={`jost font-weight-600 ${isMobile ?
                                        'font-14 font-fanzine' :
                                        'font-16'}`}
                                    style={{color: isMobile ? "" : "#AAAAAA"}}
                                    dangerouslySetInnerHTML={{
                                        __html: news && news?.news_publisher?.publisher_name,
                                    }}
                                />
                                <span
                                    className={`jost font-weight-600 ${isMobile ?
                                        'font-14 font-fanzine' :
                                        'font-14'}`}
                                    style={{color:"#AAAAAA"}}

                                >
                                    {news && news?.news_time
                                        ?.replace('minutes', 'mins')
                                        ?.replace('ago', '')
                                        ?.replace('an', '1')
                                        ?.replace('a day', '1 day')}
                                </span>
                            </div>
                            <div className={""}>
                                <span
                                    className={`jost font-weight-500  text-truncate-custom ${isMobile ? "font-13 text-white" : "font-16 text-black"}`}
                                    dangerouslySetInnerHTML={{__html: news && news?.news_title}}
                                />
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderSports = (data) => {
        return (
            <div className={`${isMobile ? "w-100" : "row"}`}>
                {
                    data !== undefined && data !== null && data?.map((newsInData, index) => {
                        const adSpace = (index + 1) % 9 === 0;
                        // const sportPath = selectedSport.toLowerCase();
                        const sportPath = newsInData?.sport;

                        return (
                            <div
                                key={index}
                                className={`m-0 col-xl-3 col-lg-3 col-md-3 p-1 col-sm-12 col-xs-12`}
                            >
                                <a
                                    href={`/${sportPath}/news/${newsInData?.slug}`}
                                    className={'text-decoration-none text-dark'}
                                    target={"_blank"}
                                    rel="noreferrer"
                                >
                                    {renderSmallCard(!isMobile ? '170px' : '160px', newsInData)}
                                </a>
                            </div>
                        );
                    })
                }
            </div>
        )
    };


    const renderMain = () => {
        return (
            <div
                style={{
                    marginTop: isMobile ? "-7%": ""
                }}
            >
                <div className={`${isMobile ? "bg-black" : "bg-white"}`}>
                    <div className={``}>
                        {
                            sliderData && sliderData?.length > 0 &&
                            <>
                                <HomeTopSlider
                                    isMobile={isMobile}
                                    sliderData={sliderData}
                                />
                            </>
                        }
                    </div>
                    <div className={`container d-flex flex-row align-items-center gap-3 pb-5`}>
                        {
                            loading ?
                                <>
                                    <Loader loading={loading}/>
                                </> :
                                <>
                                    <div className={"d-flex flex-column w-100"}>
                                        {renderLinkHeading("Latest FOOTBALL news", "/news/football")}
                                        {renderSports(footballNews)}
                                        <div className="col-12 p-1 w-100">
                                            <GoogleAdManager isMobile={isMobile} id={isMobile ? '1704953268243' : '1704953333715'}/>
                                        </div>
                                        {renderLinkHeading("Latest NBA news", "/news/nba")}
                                        {renderSports(nbaNews)}
                                        <div className="col-12 p-1 w-100">
                                            <GoogleAdManager isMobile={isMobile} id={isMobile ? '1704953268243' : '1704953333715'}/>
                                        </div>
                                        {renderLinkHeading("Latest NFL news", "/news/nfl")}
                                        {renderSports(nflNews)}
                                        <div className="col-12 p-1 w-100">
                                            <GoogleAdManager isMobile={isMobile} id={isMobile ? '1704953268243' : '1704953333715'}/>
                                        </div>
                                        {renderLinkHeading("Latest MLB news", "/news/mlb")}
                                        {renderSports(mlbNews)}
                                        <div className="col-12 p-1 w-100">
                                            <GoogleAdManager isMobile={isMobile} id={isMobile ? '1704953268243' : '1704953333715'}/>
                                        </div>
                                        {renderLinkHeading("Latest CRICKET news", "/news/cricket")}
                                        {renderSports(cricketNews)}
                                        <div className="col-12 p-1 w-100">
                                            <GoogleAdManager isMobile={isMobile} id={isMobile ? '1704953268243' : '1704953333715'}/>
                                        </div>
                                        {renderLinkHeading("Latest NCAA news", "/news/ncaa")}
                                        {renderSports(ncaaNews)}
                                        <div className="col-12 p-1 w-100">
                                            <GoogleAdManager isMobile={isMobile} id={isMobile ? '1704953268243' : '1704953333715'}/>
                                        </div>
                                        {renderLinkHeading("Latest news", "/news/womens")}
                                        {renderSports(womensNews)}
                                        <div className="col-12 p-1 w-100">
                                            <GoogleAdManager isMobile={isMobile} id={isMobile ? '1704953268243' : '1704953333715'}/>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>

        );
    };

    return renderMain();
};

export default NewHomePage;