// HomeTopSlider
import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/grid";
import 'swiper/css/navigation';
import {Autoplay, FreeMode, Navigation, Pagination} from "swiper/modules";

import "../../assets/styles/slider.css";

import {Card} from "react-bootstrap";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";

const HomeTopSlider = (props) => {
    const {
        isMobile,
        sliderData
    } = props;

    const addDefaultSrc = (ev) => {
        ev.target.src = require(
            '../../assets/images/football-shots-you-need-to-know.jpg');
    };

    const renderSliderCard = (imageHeight, news) => {
        return (
            <>
                <Card
                    className={`col border-0`}
                    style={{
                        height: isMobile ? ('210px') : (imageHeight || '245px'),
                        width: isMobile ? 'auto' : 'auto',
                    }}
                >
                    <Card.Img
                        variant="top"
                        className={`rounded-0 ${isMobile ? "" : "img-fluid"}`}
                        height={isMobile ?
                            ('210px') :
                            (imageHeight || '245px')}
                        width={isMobile ? 'auto' : 'auto'}
                        src={news && news?.news_image}
                        alt={news && news?.news_title}
                        onError={addDefaultSrc}
                        style={{
                            objectFit: 'cover',
                            // borderRadius: '15px',
                            width: isMobile ? 'auto' : 'auto',
                        }}
                    />
                    {
                        <Card.ImgOverlay
                            style={{
                                lineHeight: 1,
                            }}
                            className={`d-flex align-items-start flex-column justify-content-end ${news?.type ===
                            'videos' ? 'video-overlay' : 'news-overlay'}`}
                        >
                            <Card.Text
                                className={`text-white text-start text-wrap w-100 d-flex flex-column gap-2 bebasFont ${isMobile ? "pb-3" : "pb-3 px-5"}`}
                            >
                                {/*<span*/}
                                {/*    className={`jost me-2 font-weight-500 ${isMobile ?*/}
                                {/*        'font-14' :*/}
                                {/*        'font-20'}`}*/}
                                {/*    dangerouslySetInnerHTML={{*/}
                                {/*        __html: news && news?.news_publisher?.publisher_name,*/}
                                {/*    }}*/}
                                {/*/>*/}
                                <span
                                    className={`bebasFont font-weight-400 ${isMobile ?
                                        'font-24' :
                                        'font-62 text-uppercase w-75'}`}
                                    dangerouslySetInnerHTML={{__html: news && news?.news_title}}
                                />
                            </Card.Text>
                        </Card.ImgOverlay>
                    }
                </Card>
            </>
        )
    }
    const renderMobile = () => {
        return (
            <>
                <div>
                    <Swiper
                        id={"news-mobile-id"}
                        autoplay={true}
                        slidesPerView={1}
                        grid={{
                            rows: 1,
                        }}
                        spaceBetween={30}
                        freeMode={false}
                        pagination={{
                            clickable: true,
                            bulletClass: 'swiper-pagination-bullet',
                            modifierClass: 'swiper-pagination-mobile',
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next-custom',
                            prevEl: '.swiper-button-prev-custom',
                        }}
                        modules={[FreeMode, Navigation, Pagination, Autoplay]}
                        className={`w-full h-full position-relative`}
                    >
                        {sliderData?.map((data, index) => {
                            return (
                                <SwiperSlide
                                    virtualIndex={index + 1}
                                    key={index + "news"}>
                                    {
                                        renderSliderCard("245px", data)
                                    }
                                </SwiperSlide>
                            );
                        })}
                        <div className={`position-absolute bottom-50 start-0 w-100 d-flex flex-row align-items-center justify-content-between`} style={{zIndex:11}}>
                            <div
                                role={"button"}
                                className="swiper-button-prev-custom rounded-circle d-flex align-items-center justify-content-center ms-1"
                                style={{
                                    width: '35px',
                                    height: '35px',
                                    backgroundColor: '#FFFFFF33',
                                }}>
                                <IoIosArrowBack color={"#FFFFFFBF"} size={15}/>
                            </div>
                            <div
                                role={"button"}
                                className="swiper-button-next-custom rounded-circle d-flex align-items-center justify-content-center me-1"
                                style={{
                                    width: '35px',
                                    height: '35px',
                                    backgroundColor: '#FFFFFF33',
                                }}>
                                <IoIosArrowForward color={"#FFFFFFBF"} size={15}/>
                            </div>
                        </div>
                        <div className="position-absolute swiper-pagination-mobile"/>
                    </Swiper>
                </div>
            </>
        )
    };
    const renderLaptop = () => {
        return (
            <>
                <Swiper
                    id={"news-desktop-id"}
                    autoplay={true}
                    slidesPerView={1}
                    grid={{
                        rows: 1,
                    }}
                    navigation={{
                        nextEl: '.swiper-button-next-custom',
                        prevEl: '.swiper-button-prev-custom',
                    }}
                    spaceBetween={30}
                    freeMode={false}
                    pagination={{
                        clickable: true,
                        bulletClass: 'swiper-pagination-bullet',
                        modifierClass: 'swiper-pagination',
                    }}
                    modules={[FreeMode, Navigation, Pagination, Autoplay]}
                    className={`w-100 position-relative h-full`}
                >
                    {sliderData?.map((data, index) => {
                        return (
                            <SwiperSlide
                                virtualIndex={index + 1}
                                key={index + "news"}
                                className={"bg-dark"}
                            >
                                {
                                    renderSliderCard("690px", data)
                                }
                            </SwiperSlide>
                        );
                    })}
                    <div className={`position-absolute bottom-50 start-0 w-100 d-flex flex-row align-items-center justify-content-between`} style={{zIndex:11}}>
                        <div
                            role={"button"}
                            className="swiper-button-prev-custom rounded-circle d-flex align-items-center justify-content-center ms-4"
                            style={{
                                width: '56px',
                                height: '56px',
                                backgroundColor: '#FFFFFF33',
                            }}>
                            <IoIosArrowBack color={"#FFFFFFBF"} size={20}/>
                        </div>
                        <div
                            role={"button"}
                            className="swiper-button-next-custom rounded-circle d-flex align-items-center justify-content-center me-4"
                            style={{
                                width: '56px',
                                height: '56px',
                                backgroundColor: '#FFFFFF33',
                            }}>
                            <IoIosArrowForward color={"#FFFFFFBF"} size={20}/>
                        </div>
                    </div>
                    <div className="swiper-pagination"/>
                </Swiper>
            </>
        );
    };


    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
};

export default HomeTopSlider