let COLORS = {
    // 'localhost': '#e20001', // gunners
    'localhost': '#00965A', // celtic1967
    '127.0.0.1': '#203b79',
    'cfc-blues': '#203b79',
    'gunners': '#e20001',
    'celtic1967': '#00965A',
    'theyellows': '#FFE800',
    'villatillidie': '#670E36',
    'coywhites': '#171716',
    'coyirons': '#7A263A',
    'watp': '#034694',
    'lufcmot': '#005B9E',
    'the-toffees': '#0032A1',
    'unitedreds': '#D01A0D',
    'thekop': '#BD1423',
    'toonarmy': '#171716',
    'coys': '#002156',
    'mancityzens': '#6CABDD',
    'comeonleicester': '#00448E',
    'wearewolves': '#FDB913',
    'saints1885': '#D71920',
    'greasychipbutty': '#EE2737',
    'palaceeagles': '#1B458F',
    'coyhorns': '#171716',
    'citybluebirds': '#003B96',
    'redandblackarmy': '#CF0911',
    'the-clarets': '#731D31',
    'the-terriers': '#0E63AD',
    'foresttilidie': '#ED1B2D',
    'goonstoke': '#DB2E34',
    'theteessiders': '#E30613',
    'roverstilidie': '#004792',
    'boingboingbaggies': '#122E66',
    'bcfckro': '#1B3F8F',
    'comeonderby': '#171716',
    'fanzinesports': '#171716',
    'fanzine': '#171716',
    'weareswansea': '#171716',
    'oneteaminbristol': '#E30613',
    'coybees': '#E30613',
    'rokerroar': '#EB172B',
    'weareqpr': '#0047B5',
    'wednesdayites': '#0047B5',
    'thetractorboys': '#0033A0',
    'playuppompey': '#293F9C',
    'bhaseagulls': '#0057B8',
    'the-gulls': '#0057B8',
    'lablaugrana': '#004D98',
    'cim-bom': '#A90432',
    'barca': '#004D98',
    'hala-madrid': '#2B3441',
    'die-bayern': '#D90B29',
    'forza-juve': '#171716',
    'allezparis': '#182434',
    'wxmafc': '#C31018',
    'theherons': '#FF7BAC' ,
    'hatters1885': '#f08b1e',
};

export {COLORS};

let TEXT_COLORS = {
    // 'localhost': '#e20001', // gunners
    'localhost': '#00965A', // celtic1967
    '127.0.0.1': '#203b79',
    'cfc-blues': '#203b79',
    'gunners': '#e20001',
    'celtic1967': '#00965A',
    'theyellows': '#00A650',
    'villatillidie': '#670E36',
    'coywhites': '#171716',
    'coyirons': '#7A263A',
    'watp': '#034694',
    'lufcmot': '#005B9E',
    'the-toffees': '#0032A1',
    'unitedreds': '#D01A0D',
    'thekop': '#BD1423',
    'toonarmy': '#171716',
    'coys': '#002156',
    // 'mancityzens': '#ffffff',
    'mancityzens': '#6cabdd',
    'comeonleicester': '#00448E',
    'wearewolves': '#000000',
    'saints1885': '#D71920',
    'greasychipbutty': '#EE2737',
    'palaceeagles': '#1B458F',
    'coyhorns': '#171716',
    'citybluebirds': '#003B96',
    'redandblackarmy': '#CF0911',
    'the-clarets': '#731D31',
    'the-terriers': '#0E63AD',
    'foresttilidie': '#ED1B2D',
    'goonstoke': '#DB2E34',
    'theteessiders': '#E30613',
    'roverstilidie': '#004792',
    'boingboingbaggies': '#122E66',
    'bcfckro': '#1B3F8F',
    'comeonderby': '#171716',
    'fanzinesports': '#171716',
    'fanzine': '#171716',
    'weareswansea': '#171716',
    'oneteaminbristol': '#E30613',
    'coybees': '#E30613',
    'rokerroar': '#EB172B',
    'weareqpr': '#0047B5',
    'wednesdayites': '#0047B5',
    'thetractorboys': '#0033A0',
    'playuppompey': '#293F9C',
    'bhaseagulls': '#0057B8',
    'the-gulls': '#0057B8',
    'lablaugrana': '#004D98',
    'cim-bom': '#A90432',
    'barca': '#004D98',
    'hala-madrid': '#2B3441',
    'die-bayern': '#D90B29',
    'forza-juve': '#171716',
    'allezparis': '#182434',
    'wxmafc': '#C31018',
    'theherons': '#FF7BAC' ,
    'hatters1885': '#f08b1e',
};

export {TEXT_COLORS};

    let COLORS_RGB = {
    // 'localhost': 'rgb(226,0,1)', // gunners
    'localhost': 'rgb(0,150,90)', // celtic1967
    '127.0.0.1': 'rgb(32,59,121)', //cfc-blues
    'gunners': 'rgb(226,0,1)',
    'cfc-blues': 'rgb(32,59,121)',
    'celtic1967': 'rgb(0,150,90)',
    'theyellows': 'rgb(255,237,0)',
    'villatillidie': 'rgb(103,14,54)',
    'coywhites': 'rgb(23,23,22)',
    'coyirons': 'rgb(122,38,58)',
    'watp': 'rgb(3,70,148)',
    'lufcmot': 'rgb(0,91,158)',
    'the-toffees': 'rgb(0,50,161)',
    'unitedreds': 'rgb(208,26,13)',
    'thekop': 'rgb(189,20,35)',
    'toonarmy': 'rgb(23,23,22)',
    'coys': 'rgb(0,33,86)',
    'mancityzens': 'rgb(108,171,221)',
    'comeonleicester': 'rgb(0,68,142)',
    'wearewolves': 'rgb(253,185,19)',
    'saints1885': 'rgb(215,25,32)',
    'greasychipbutty': 'rgb(238,39,55)',
    'palaceeagles': 'rgb(27,69,143)',
    'coyhorns': 'rgb(23,23,22)',
    'citybluebirds': 'rgb(0,59,150)',
    'redandblackarmy': 'rgb(207,9,17)',
    'the-clarets': 'rgb(115,29,49)',
    'the-terriers': 'rgb(14,99,173)',
    'foresttilidie': 'rgb(237,27,45)',
    'goonstoke': 'rgb(219,46,52)',
    'theteessiders': 'rgb(227,6,19)',
    'roverstilidie': 'rgb(0,71,146)',
    'boingboingbaggies': 'rgb(18,46,102)',
    'bcfckro': 'rgb(27,63,143)',
    'comeonderby': 'rgb(23,23,22)',
    'fanzinesports': 'rgb(23,23,22)',
    'fanzine': 'rgb(23,23,22)',
    'weareswansea': 'rgb(23,23,22)',
    'oneteaminbristol': 'rgb(227,6,19)',
    'coybees': 'rgb(227,6,19)',
    'rokerroar': 'rgb(235,23,43)',
    'weareqpr': 'rgb(0,71,181)',
    'wednesdayites': 'rgb(0,71,181)',
    'thetractorboys': 'rgb(0,51,160)',
    'playuppompey': 'rgb(41,63,156)',
    'bhaseagulls': 'rgb(0,87,184)',
    'the-gulls': 'rgb(0,87,184)',
    'lablaugrana': 'rgb(0,77,152)',
    'cim-bom': 'rgb(169,4,50)',
    'barca': 'rgb(0,77,152)',
    'hala-madrid': 'rgb(43,52,65)',
    'die-bayern': 'rgb(217,11,41)',
    'forza-juve': 'rgb(23,23,22)',
    'allezparis': 'rgb(24,36,52)',
    'wxmafc': 'rgb(195,16,24)',
    'theherons': 'rgb(255, 123, 172)',
    'hatters1885': 'rgb(240, 139, 30)',

    };

export {COLORS_RGB};

let COLORS_RGBA = {
    // 'localhost': 'rgba(226,0,1,', // gunners
    '127.0.0.1': 'rgba(32,59,121,', // cfc-blues
    'localhost': 'rgba(0,150,90,', // celtics1967
    'gunners': 'rgba(226,0,1,',
    'cfc-blues': 'rgba(32,59,121,',
    'celtic1967': 'rgba(0,150,90,',
    'theyellows': 'rgba(0,166,80,',
    'villatillidie': 'rgba(103,14,54,',
    'coywhites': 'rgba(23,23,22,',
    'coyirons': 'rgba(122,38,58,',
    'watp': 'rgba(3,70,148,',
    'lufcmot': 'rgba(0,91,158,',
    'the-toffees': 'rgba(0,50,161,',
    'unitedreds': 'rgba(208,26,13,',
    'thekop': 'rgba(189,20,35,',
    'toonarmy': 'rgba(23,23,22,',
    'coys': 'rgba(0,33,86,',
    'mancityzens': 'rgba(108,171,221,',
    'comeonleicester': 'rgba(0,68,142,',
    'wearewolves': 'rgba(0,0,0,',
    'saints1885': 'rgba(215,25,32,',
    'greasychipbutty': 'rgba(238,39,55,',
    'palaceeagles': 'rgba(27,69,143,',
    'coyhorns': 'rgba(23,23,22,',
    'citybluebirds': 'rgba(0,59,150,',
    'redandblackarmy': 'rgba(207,9,17,',
    'the-clarets': 'rgba(115,29,49,',
    'the-terriers': 'rgba(14,99,173,',
    'foresttilidie': 'rgba(237,27,45,',
    'goonstoke': 'rgba(219,46,52,',
    'theteessiders': 'rgba(227,6,19,',
    'roverstilidie': 'rgba(0,71,146,',
    'boingboingbaggies': 'rgba(18,46,102,',
    'bcfckro': 'rgba(27,63,143,',
    'comeonderby': 'rgba(23,23,23,',
    'fanzinesports': 'rgba(23,23,23,',
    'fanzine': 'rgba(23,23,23,',
    'weareswansea': 'rgba(23,23,23,',
    'oneteaminbristol': 'rgba(227,6,19,',
    'coybees': 'rgba(227,6,19,',
    'rokerroar': 'rgba(235,23,43,',
    'weareqpr': 'rgba(0,71,181,',
    'wednesdayites': 'rgba(0,71,181,',
    'thetractorboys': 'rgba(0,51,160,',
    'playuppompey': 'rgba(41,62,156,',
    'bhaseagulls': 'rgba(0,87,184,',
    'the-gulls': 'rgba(0,87,184,',
    'lablaugrana': 'rgba(0,77,152,',
    'cim-bom': 'rgba(169,4,50,',
    'barca': 'rgba(0,77,152,',
    'hala-madrid': 'rgba(43,52,65,',
    'die-bayern': 'rgba(217,11,41,',
    'forza-juve': 'rgba(23,23,22,',
    'allezparis': 'rgba(24,36,52,',
    'wxmafc': 'rgba(195,16,24,',
    'theherons': 'rgba(255,123,172,',
    'hatters1885': 'rgb(240, 139, 30,',

};

export {COLORS_RGBA};

let NAV_CLASS = {
    // 'localhost': 'bg-gunners',
    'localhost': 'bg-celtic1967',
    '127.0.0.1': 'bg-cfc-blues',
    'cfc-blues': 'bg-cfc-blues',
    'celtic1967': 'bg-celtic1967',
    'gunners': 'bg-gunners',
    'theyellows': 'bg-theyellows',
    'villatillidie': 'bg-villatillidie',
    'coywhites': 'bg-coywhites',
    'coyirons': 'bg-coyirons',
    'watp': 'bg-watp',
    'lufcmot': 'bg-lufcmot',
    'the-toffees': 'bg-the-toffees',
    'unitedreds': 'bg-unitedreds',
    'thekop': 'bg-thekop',
    'toonarmy': 'bg-toonarmy',
    'coys': 'bg-coys',
    'mancityzens': 'bg-mancityzens',
    'comeonleicester': 'bg-comeonleicester',
    'wearewolves': 'bg-wearewolves',
    'saints1885': 'bg-saints1885',
    'greasychipbutty': 'bg-greasychipbutty',
    'palaceeagles': 'bg-palaceeagles',
    'coyhorns': 'bg-coyhorns',
    'citybluebirds': 'bg-citybluebirds',
    'redandblackarmy': 'bg-redandblackarmy',
    'the-clarets': 'bg-the-clarets',
    'the-terriers': 'bg-the-terriers',
    'foresttilidie': 'bg-foresttilidie',
    'goonstoke': 'bg-goonstoke',
    'theteessiders': 'bg-theteessiders',
    'roverstilidie': 'bg-roverstilidie',
    'boingboingbaggies': 'bg-boingboingbaggies',
    'bcfckro': 'bg-bcfckro',
    'comeonderby': 'bg-comeonderby',
    'fanzinesports': 'bg-fanzinesports',
    'fanzine': 'bg-fanzine',
    'weareswansea': 'bg-weareswansea',
    'oneteaminbristol': 'bg-oneteaminbristol',
    'coybees': 'bg-coybees',
    'rokerroar': 'bg-rokerroar',
    'weareqpr': 'bg-weareqpr',
    'wednesdayites': 'bg-wednesdayites',
    'thetractorboys': 'bg-thetractorboys',
    'playuppompey': 'bg-playuppompey',
    'bhaseagulls': 'bg-bhaseagulls',
    'the-gulls': 'bg-the-gulls',
    'lablaugrana': 'bg-lablaugrana',
    'cim-bom': 'bg-cim-bom',
    'barca': 'bg-barca',
    'hala-madrid': 'bg-hala-madrid',
    'die-bayern': 'bg-die-bayern',
    'forza-juve': 'bg-forza-juve',
    'allezparis': 'bg-allezparis',
    'wxmafc': 'bg-wxmafc',
    'theherons': 'bg-theherons',
    'hatters1885': 'bg-hatters1885',

};

export {NAV_CLASS};

let TEXT_COLOR = {
    // 'localhost': 'text-gunners',
    '127.0.0.1': 'text-cfc-blues',
    'localhost': 'text-celtic1967',
    'cfc-blues': 'text-cfc-blues',
    'gunners': 'text-gunners',
    'celtic1967': 'text-celtic1967',
    'theyellows': 'text-theyellows',
    'villatillidie': 'text-villatillidie',
    'coywhites': 'text-coywhites',
    'coyirons': 'text-coyirons',
    'watp': 'text-watp',
    'lufcmot': 'text-lufcmot',
    'the-toffees': 'text-the-toffees',
    'unitedreds': 'text-unitedreds',
    'thekop': 'text-thekop',
    'toonarmy': 'text-toonarmy',
    'coys': 'text-coys',
    'comeonleicester': 'text-comeonleicester',
    'wearewolves': 'text-wearewolves',
    'saints1885': 'text-saints1885',
    'greasychipbutty': 'text-greasychipbutty',
    'palaceeagles': 'text-palaceeagles',
    'coyhorns': 'text-coyhorns',
    'citybluebirds': 'text-citybluebirds',
    'redandblackarmy': 'text-redandblackarmy',
    'the-clarets': 'text-the-clarets',
    'the-terriers': 'text-the-terriers',
    'foresttilidie': 'text-foresttilidie',
    'goonstoke': 'text-goonstoke',
    'theteessiders': 'text-theteessiders',
    'roverstilidie': 'text-roverstilidie',
    'boingboingbaggies': 'text-boingboingbaggies',
    'bcfckro': 'text-bcfckro',
    'comeonderby': 'text-comeonderby',
    'fanzinesports': 'text-fanzinesports',
    'fanzine': 'text-fanzine',
    'weareswansea': 'text-weareswansea',
    'mancityzens':'text-mancityzens',
    'oneteaminbristol': 'text-oneteaminbristol',
    'coybees': 'text-coybees',
    'rokerroar': 'text-rokerroar',
    'weareqpr': 'text-weareqpr',
    'wednesdayites': 'text-wednesdayites',
    'thetractorboys': 'text-thetractorboys',
    'playuppompey': 'text-playuppompey',
    'bhaseagulls': 'text-bhaseagulls',
    'the-gulls': 'text-the-gulls',
    'lablaugrana': 'text-lablaugrana',
    'cim-bom': 'text-cim-bom',
    'barca': 'text-barca',
    'hala-madrid': 'text-hala-madrid',
    'die-bayern': 'text-die-bayern',
    'forza-juve': 'text-forza-juve',
    'allezparis': 'text-allezparis',
    'wxmafc': 'text-wxmafc',
    'theherons': 'text-theherons',
    'hatters1885': 'text-hatters1885',
};

export {TEXT_COLOR};

let FONT_COLOR = {
    // 'localhost': 'text-gunners',
    '127.0.0.1': 'font-cfc-blues',
    'localhost': 'font-celtic1967',
    'cfc-blues': 'font-cfc-blues',
    'gunners': 'font-gunners',
    'celtic1967': 'font-celtic1967',
    'theyellows': 'font-theyellows',
    'villatillidie': 'font-villatillidie',
    'coywhites': 'font-coywhites',
    'coyirons': 'font-coyirons',
    'watp': 'font-watp',
    'lufcmot': 'font-lufcmot',
    'the-toffees': 'font-the-toffees',
    'unitedreds': 'font-unitedreds',
    'thekop': 'font-thekop',
    'toonarmy': 'font-toonarmy',
    'coys': 'font-coys',
    'comeonleicester': 'font-comeonleicester',
    'wearewolves': 'font-wearewolves',
    'saints1885': 'font-saints1885',
    'greasychipbutty': 'font-greasychipbutty',
    'palaceeagles': 'font-palaceeagles',
    'coyhorns': 'font-coyhorns',
    'mancityzens':'font-mancityzens',
    'citybluebirds': 'font-citybluebirds',
    'redandblackarmy': 'font-redandblackarmy',
    'the-clarets': 'font-the-clarets',
    'the-terriers': 'font-the-terriers',
    'foresttilidie': 'font-foresttilidie',
    'goonstoke': 'font-goonstoke',
    'theteessiders': 'font-theteessiders',
    'roverstilidie': 'font-roverstilidie',
    'boingboingbaggies': 'font-boingboingbaggies',
    'bcfckro': 'font-bcfckro',
    'comeonderby': 'font-comeonderby',
    'fanzinesports': 'font-fanzinesports',
    'fanzine': 'font-fanzine',
    'weareswansea': 'font-weareswansea',
    'oneteaminbristol': 'font-oneteaminbristol',
    'coybees': 'font-coybees',
    'rokerroar': 'font-rokerroar',
    'weareqpr': 'font-weareqpr',
    'wednesdayites': 'font-wednesdayites',
    'thetractorboys': 'font-thetractorboys',
    'playuppompey': 'font-playuppompey',
    'bhaseagulls': 'font-bhaseagulls',
    'the-gulls': 'font-the-gulls',
    'lablaugrana': 'font-lablaugrana',
    'cim-bom': 'font-cim-bom',
    'barca': 'font-barca',
    'hala-madrid': 'font-hala-madrid',
    'die-bayern': 'font-die-bayern',
    'forza-juve': 'font-forza-juve',
    'allezparis': 'font-allezparis',
    'wxmafc': 'font-wxmafc',
    'theherons': 'font-theherons',
    'hatters1885': 'font-hatters1885',

};

export {FONT_COLOR};

let LOGO = {
    // 'localhost': require('../assets/images/team_icons/gunners/gunners_2x.png'), // gunners
    'localhost': require('../assets/images/team_icons/celtics1967/Celtic_Green_PR_18.png'), // celtics1967
    '127.0.0.1': require('../assets/images/team_icons/cfc-blues/cfc-blues.png'),
    'cfc-blues': require('../assets/images/team_icons/cfc-blues/cfc-blues.png'),
    'gunners': require('../assets/images/team_icons/gunners/gunners_2x.png'),
    'celtic1967': require('../assets/images/team_icons/celtics1967/Celtic_Green_PR_18.png'),
    'theyellows': require('../assets/images/team_icons/theyellows/theyellows_logo.png'),
    'villatillidie': require('../assets/images/team_icons/villatillidie/villatillidie.png'),
    'coywhites': require('../assets/images/team_icons/coywhites/coywhites_2x.png'),
    'coyirons': require('../assets/images/team_icons/coyirons/coyirons_2x.png'),
    'watp': require('../assets/images/team_icons/watp/watp.png'),
    'lufcmot': require('../assets/images/team_icons/lufcmot/lufcmot.png'),
    'the-toffees': require('../assets/images/team_icons/the-toffees/the-toffees.png'),
    'unitedreds': require('../assets/images/team_icons/unitedreds/unitedreds.png'),
    'thekop': require('../assets/images/team_icons/thekop/thekop.png'),
    'toonarmy': require('../assets/images/team_icons/toonarmy/toonarmy.png'),
    'coys': require('../assets/images/team_icons/coys/coys.png'),
    'mancityzens': require('../assets/images/team_icons/mancityzens/mancityzens.png'),
    'comeonleicester': require('../assets/images/team_icons/comeonleicester/comeonleicester.png'),
    'wearewolves': require('../assets/images/team_icons/wearewolves/wearewolves.png'),
    'saints1885': require('../assets/images/team_icons/saints1885/saints1885.png'),
    'greasychipbutty': require('../assets/images/team_icons/gcb/gcb.png'),
    'palaceeagles': require('../assets/images/team_icons/palaceeagles/palaceeagles.png'),
    'coyhorns': require('../assets/images/team_icons/coyhorns/coyhorns.png'),
    'citybluebirds': require('../assets/images/team_icons/citybluebirds/citybluebirds.png'),
    'redandblackarmy': require('../assets/images/team_icons/redandblackarmy/redandblackarmy.png'),
    'the-clarets': require('../assets/images/team_icons/the-clarets/the-clarets.png'),
    'the-terriers': require('../assets/images/team_icons/the-terriers/the-terriers.png'),
    'foresttilidie': require('../assets/images/team_icons/foresttilidie/foresttilidie.png'),
    'goonstoke': require('../assets/images/team_icons/goonstoke/goonstoke.png'),
    'theteessiders': require('../assets/images/team_icons/theteessiders/theteesiders.png'),
    'roverstilidie': require('../assets/images/team_icons/roverstilidie/roverstilidie.png'),
    'boingboingbaggies': require('../assets/images/team_icons/boingboingbaggies/boingboingbaggies.png'),
    'bcfckro': require('../assets/images/team_icons/bcfckro/bcfckro.png'),
    'comeonderby': require('../assets/images/team_icons/comeonderby/comeonderby.png'),
    // 'fanzinesports': require('../assets/images/team_icons/fanzinesports/fanzinesports.png'),
    // 'fanzine': require('../assets/images/team_icons/fanzinesports/fanzinesports.png'),
    'fanzine': require('../assets/images/v2/newFanzineLogoBlack01@3x.png'),
    'fanzinesports': require('../assets/images/fanzineSportsLogo.png'),
    'weareswansea': require('../assets/images/team_icons/weareswansea/weareswansea.png'),
    'oneteaminbristol': require('../assets/images/team_icons/oneteaminbristol/oneteaminbristol.png'),
    'coybees': require('../assets/images/team_icons/coybees/coybees.png'),
    'rokerroar': require('../assets/images/team_icons/rokerroar/rokerroar.png'),
    'weareqpr': require('../assets/images/team_icons/weareqpr/weareqpr.png'),
    'wednesdayites': require('../assets/images/team_icons/wednesdayites/wednesdayites.png'),
    'thetractorboys': require('../assets/images/team_icons/thetractorboys/thetractorboys.png'),
    'playuppompey': require('../assets/images/team_icons/playuppompey/playuppompey.png'),
    'bhaseagulls': require('../assets/images/team_icons/bhaseagulls/bhaseagulls.png'),
    'the-gulls': require('../assets/images/team_icons/bhaseagulls/bhaseagulls.png'),
    'lablaugrana': require('../assets/images/team_icons/lablaugrana/lablaugrana.png'),
    'cim-bom': require('../assets/images/team_icons/cim-bom/cim-bom.png'),
    'barca': require('../assets/images/team_icons/barca/barca.png'),
    'hala-madrid': require('../assets/images/team_icons/hala-madrid/hala-madrid.png'),
    'die-bayern': require('../assets/images/team_icons/die-bayern/die-bayern.png'),
    'forza-juve': require('../assets/images/team_icons/forza-juve/forza-juve.png'),
    'allezparis': require('../assets/images/team_icons/allezparis/allezparis.png'),
    'wxmafc': require('../assets/images/team_icons/wxmafc/wxmafc.png'),
    'theherons': require('../assets/images/team_icons/the-herons/the-herons.png'),
    'hatters1885': require("../assets/images/team_icons/hatters1885/Luton Town App Icon.png"),
};

export {LOGO};

let LOGO_MEDIUM = {
    // 'localhost': require('../assets/images/team_icons/gunners/gunners_1x.png'),
    '127.0.0.1': require('../assets/images/team_icons/cfc-blues/cfc-blues.png'),
    'localhost': require('../assets/images/team_icons/celtics1967/Celtic_Green_PR_18.png'),
    'celtic1967': require('../assets/images/team_icons/celtics1967/Celtic_Green_PR_18.png'),
    'cfc-blues': require('../assets/images/team_icons/cfc-blues/cfc-blues.png'),
    'gunners': require('../assets/images/team_icons/gunners/gunners_1x.png'),
    'theyellows': require('../assets/images/team_icons/theyellows/theyellows_logo.png'),
    'villatillidie': require('../assets/images/team_icons/villatillidie/villatillidie.png'),
    'coywhites': require('../assets/images/team_icons/coywhites/coywhites_1x.png'),
    'coyirons': require('../assets/images/team_icons/coyirons/coyirons_1x.png'),
    'watp': require('../assets/images/team_icons/watp/watp.png'),
    'lufcmot': require('../assets/images/team_icons/lufcmot/lufcmot.png'),
    'the-toffees': require('../assets/images/team_icons/the-toffees/the-toffees.png'),
    'unitedreds': require('../assets/images/team_icons/unitedreds/unitedreds.png'),
    'thekop': require('../assets/images/team_icons/thekop/thekop.png'),
    'toonarmy': require('../assets/images/team_icons/toonarmy/toonarmy.png'),
    'coys': require('../assets/images/team_icons/coys/coys.png'),
    'mancityzens': require('../assets/images/team_icons/mancityzens/mancityzens.png'),
    'comeonleicester': require('../assets/images/team_icons/comeonleicester/comeonleicester.png'),
    'wearewolves': require('../assets/images/team_icons/wearewolves/wearewolves.png'),
    'saints1885': require('../assets/images/team_icons/saints1885/saints1885.png'),
    'greasychipbutty': require('../assets/images/team_icons/gcb/gcb.png'),
    'palaceeagles': require('../assets/images/team_icons/palaceeagles/palaceeagles.png'),
    'coyhorns': require('../assets/images/team_icons/coyhorns/coyhorns.png'),
    'citybluebirds': require('../assets/images/team_icons/citybluebirds/citybluebirds.png'),
    'redandblackarmy': require('../assets/images/team_icons/redandblackarmy/redandblackarmy.png'),
    'the-clarets': require('../assets/images/team_icons/the-clarets/the-clarets.png'),
    'the-terriers': require('../assets/images/team_icons/the-terriers/the-terriers.png'),
    'foresttilidie': require('../assets/images/team_icons/foresttilidie/foresttilidie.png'),
    'goonstoke': require('../assets/images/team_icons/goonstoke/goonstoke.png'),
    'theteessiders': require('../assets/images/team_icons/theteessiders/theteesiders.png'),
    'roverstilidie': require('../assets/images/team_icons/roverstilidie/roverstilidie.png'),
    'boingboingbaggies': require('../assets/images/team_icons/boingboingbaggies/boingboingbaggies.png'),
    'bcfckro': require('../assets/images/team_icons/bcfckro/bcfckro.png'),
    'comeonderby': require('../assets/images/team_icons/comeonderby/comeonderby.png'),
    // 'fanzinesports': require('../assets/images/team_icons/fanzinesports/fanzinesports.png'),
    // 'fanzine': require('../assets/images/team_icons/fanzinesports/fanzinesports.png'),
    'fanzine': require('../assets/images/v2/newFanzineLogoBlack01@3x.png'),
    'fanzinesports':  require('../assets/images/v2/newFanzineLogoBlack01@3x.png'),

    'weareswansea': require('../assets/images/team_icons/weareswansea/weareswansea.png'),
    'oneteaminbristol': require('../assets/images/team_icons/oneteaminbristol/oneteaminbristol.png'),
    'coybees': require('../assets/images/team_icons/coybees/coybees.png'),
    'rokerroar': require('../assets/images/team_icons/rokerroar/rokerroar.png'),
    'weareqpr': require('../assets/images/team_icons/weareqpr/weareqpr.png'),
    'wednesdayites': require('../assets/images/team_icons/wednesdayites/wednesdayites.png'),
    'thetractorboys': require('../assets/images/team_icons/thetractorboys/thetractorboys.png'),
    'playuppompey': require('../assets/images/team_icons/playuppompey/playuppompey.png'),
    'bhaseagulls': require('../assets/images/team_icons/bhaseagulls/bhaseagulls.png'),
    'the-gulls': require('../assets/images/team_icons/bhaseagulls/bhaseagulls.png'),
    'lablaugrana': require('../assets/images/team_icons/lablaugrana/lablaugrana.png'),
    'cim-bom': require('../assets/images/team_icons/cim-bom/cim-bom-white.png'),
    'barca': require('../assets/images/team_icons/barca/barca.png'),
    'hala-madrid': require('../assets/images/team_icons/hala-madrid/hala-madrid.png'),
    'die-bayern': require('../assets/images/team_icons/die-bayern/die-bayern.png'),
    'forza-juve': require('../assets/images/team_icons/forza-juve/forza-juve.png'),
    'allezparis': require('../assets/images/team_icons/allezparis/allezparis.png'),
    'wxmafc': require('../assets/images/team_icons/wxmafc/wxmafc.png'),
    'theherons': require('../assets/images/team_icons/the-herons/the-herons2x.png'),
    'hatters1885': require("../assets/images/team_icons/hatters1885/Luton Town App Icon.png"),
};

export {LOGO_MEDIUM};

let LOGO_SMALL = {
    // 'localhost': require('../assets/images/new_gunners_logo.png'),
    '127.0.0.1': require('../assets/images/team_icons/cfc-blues/cfc-blues.png'),
    'localhost': require('../assets/images/team_icons/celtics1967/Celtic_Green_PR_18.png'), // celtics1967
    'cfc-blues': require('../assets/images/team_icons/cfc-blues/cfc-blues.png'),
    'gunners': require('../assets/images/new_gunners_logo.png'),
    'celtic1967': require('../assets/images/team_icons/celtics1967/Celtic_Green_PR_18.png'),
    'theyellows': require('../assets/images/team_icons/theyellows/theyellows_logo.png'),
    'villatillidie': require('../assets/images/team_icons/villatillidie/villatillidie.png'),
    'coywhites': require('../assets/images/team_icons/coywhites/coywhites_1x.png'),
    'coyirons': require('../assets/images/team_icons/coyirons/coyirons_1x.png'),
    'watp': require('../assets/images/team_icons/watp/watp.png'),
    'lufcmot': require('../assets/images/team_icons/lufcmot/lufcmot.png'),
    'the-toffees': require('../assets/images/team_icons/the-toffees/the-toffees.png'),
    'unitedreds': require('../assets/images/team_icons/unitedreds/unitedreds.png'),
    'thekop': require('../assets/images/team_icons/thekop/thekop.png'),
    'toonarmy': require('../assets/images/team_icons/toonarmy/toonarmy.png'),
    'coys': require('../assets/images/team_icons/coys/coys.png'),
    'mancityzens': require('../assets/images/team_icons/mancityzens/mancityzens.png'),
    'comeonleicester': require('../assets/images/team_icons/comeonleicester/comeonleicester.png'),
    'wearewolves': require('../assets/images/team_icons/wearewolves/wearewolves.png'),
    'saints1885': require('../assets/images/team_icons/saints1885/saints1885.png'),
    'greasychipbutty': require('../assets/images/team_icons/gcb/gcb.png'),
    'palaceeagles': require('../assets/images/team_icons/palaceeagles/palaceeagles.png'),
    'coyhorns': require('../assets/images/team_icons/coyhorns/coyhorns.png'),
    'citybluebirds': require('../assets/images/team_icons/citybluebirds/citybluebirds.png'),
    'redandblackarmy': require('../assets/images/team_icons/redandblackarmy/redandblackarmy.png'),
    'the-clarets': require('../assets/images/team_icons/the-clarets/the-clarets.png'),
    'the-terriers': require('../assets/images/team_icons/the-terriers/the-terriers.png'),
    'foresttilidie': require('../assets/images/team_icons/foresttilidie/foresttilidie.png'),
    'goonstoke': require('../assets/images/team_icons/goonstoke/goonstoke.png'),
    'theteessiders': require('../assets/images/team_icons/theteessiders/theteesiders.png'),
    'roverstilidie': require('../assets/images/team_icons/roverstilidie/roverstilidie.png'),
    'boingboingbaggies': require('../assets/images/team_icons/boingboingbaggies/boingboingbaggies.png'),
    'bcfckro': require('../assets/images/team_icons/bcfckro/bcfckro.png'),
    'comeonderby': require('../assets/images/team_icons/comeonderby/comeonderby.png'),
    // 'fanzinesports': require('../assets/images/team_icons/fanzinesports/fanzine_favicon.png'),
    // 'fanzine': require('../assets/images/team_icons/fanzinesports/fanzine_favicon.png'),
    'weareswansea': require('../assets/images/team_icons/weareswansea/weareswansea.png'),
    'fanzine': require('../assets/images/v2/newFanzineLogoBlack01@3x.png'),
    'fanzinesports': require('../assets/images/v2/newFanzineLogoBlack01@3x.png'),

    'oneteaminbristol': require('../assets/images/team_icons/oneteaminbristol/oneteaminbristol.png'),
    'coybees': require('../assets/images/team_icons/coybees/coybees.png'),
    'rokerroar': require('../assets/images/team_icons/rokerroar/rokerroar.png'),
    'weareqpr': require('../assets/images/team_icons/weareqpr/weareqpr.png'),
    'wednesdayites': require('../assets/images/team_icons/wednesdayites/wednesdayites.png'),
    'thetractorboys': require('../assets/images/team_icons/thetractorboys/thetractorboys.png'),
    'playuppompey': require('../assets/images/team_icons/playuppompey/playuppompey.png'),
    'bhaseagulls': require('../assets/images/team_icons/bhaseagulls/bhaseagulls.png'),
    'the-gulls': require('../assets/images/team_icons/bhaseagulls/bhaseagulls.png'),
    'lablaugrana': require('../assets/images/team_icons/lablaugrana/lablaugrana.png'),
    'cim-bom': require('../assets/images/team_icons/cim-bom/cim-bom.png'),
    'barca': require('../assets/images/team_icons/barca/barca.png'),
    'hala-madrid': require('../assets/images/team_icons/hala-madrid/hala-madrid.png'),
    'die-bayern': require('../assets/images/team_icons/die-bayern/die-bayern.png'),
    'forza-juve': require('../assets/images/team_icons/forza-juve/forza-juve.png'),
    'allezparis': require('../assets/images/team_icons/allezparis/allezparis.png'),
    'wxmafc': require('../assets/images/team_icons/wxmafc/wxmafc.png'),
    'theherons': require('../assets/images/team_icons/the-herons/the-herons3x.png'),
    'hatters1885': require("../assets/images/team_icons/hatters1885/Luton Town App Icon.png"),

};

export {LOGO_SMALL};

let LOGO_BANNER = {
    // 'localhost': require('../assets/images/new_gunners_logo.png'),
    '127.0.0.1': require('../assets/images/team_icons/cfc-blues/cfc-blues_banner.png'),
    'localhost': require('../assets/images/team_icons/celtics1967/celtic1967_banner.png'), // celtics1967
    'cfc-blues': require('../assets/images/team_icons/cfc-blues/cfc-blues_banner.png'),
    'gunners': require('../assets/images/team_icons/gunners/gunners_banner.png'),
    'celtic1967': require('../assets/images/team_icons/celtics1967/celtic1967_banner.png'),
    'theyellows': require('../assets/images/team_icons/theyellows/theyellows_banner.png'),
    'villatillidie': require('../assets/images/team_icons/villatillidie/villatillidie_banner.png'),
    'coywhites': require('../assets/images/team_icons/coywhites/coywhites_banner.png'),
    'coyirons': require('../assets/images/team_icons/coyirons/coyirons_banner.png'),
    'watp': require('../assets/images/team_icons/watp/watp_banner.png'),
    'lufcmot': require('../assets/images/team_icons/lufcmot/lufcmot_banner.png'),
    'the-toffees': require('../assets/images/team_icons/the-toffees/the-toffees_banner.png'),
    'unitedreds': require('../assets/images/team_icons/unitedreds/unitedreds_banner.png'),
    'thekop': require('../assets/images/team_icons/thekop/thekop_banner.png'),
    'toonarmy': require('../assets/images/team_icons/toonarmy/toonarmy_banner.png'),
    'coys': require('../assets/images/team_icons/coys/coys_banner.png'),
    'mancityzens': require('../assets/images/team_icons/mancityzens/mancityzens_banner.png'),
    'comeonleicester': require('../assets/images/team_icons/comeonleicester/comeonleicester_banner.png'),
    'wearewolves': require('../assets/images/team_icons/wearewolves/wearewolves_banner.png'),
    'saints1885': require('../assets/images/team_icons/saints1885/saints1885_banner.png'),
    'greasychipbutty': require('../assets/images/team_icons/gcb/gcb_banner.png'),
    'palaceeagles': require('../assets/images/team_icons/palaceeagles/palaceeagles_banner.png'),
    'coyhorns': require('../assets/images/team_icons/coyhorns/coyhorns_banner.png'),
    'citybluebirds': require('../assets/images/team_icons/citybluebirds/citybluebirds_banner.png'),
    'redandblackarmy': require('../assets/images/team_icons/redandblackarmy/redandblackarmy_banner.png'),
    'the-clarets': require('../assets/images/team_icons/the-clarets/the-clarets_banner.png'),
    'the-terriers': require('../assets/images/team_icons/the-terriers/the-terriers_banner.png'),
    'foresttilidie': require('../assets/images/team_icons/foresttilidie/foresttilidie_banner.png'),
    'goonstoke': require('../assets/images/team_icons/goonstoke/goonstoke_banner.png'),
    'theteessiders': require('../assets/images/team_icons/theteessiders/theteesiders_banner.png'),
    'roverstilidie': require('../assets/images/team_icons/roverstilidie/roverstilidie_banner.png'),
    'boingboingbaggies': require('../assets/images/team_icons/boingboingbaggies/boingboingbaggies_banner.png'),
    'bcfckro': require('../assets/images/team_icons/bcfckro/bcfckro_banner.png'),
    'comeonderby': require('../assets/images/team_icons/comeonderby/comeonderby_banner.png'),
    'fanzinesports': require('../assets/images/team_icons/fanzinesports/fanzinesports.png'),
    // 'fanzine': require('../assets/images/team_icons/fanzinesports/fanzinesports.png'),
    'fanzine': require('../assets/images/v2/newFanzineLogoBlack01@3x.png'),

    'weareswansea': require('../assets/images/team_icons/weareswansea/weareswansea_banner.png'),
    'oneteaminbristol': require('../assets/images/team_icons/oneteaminbristol/oneteaminbristol_banner.png'),
    'coybees': require('../assets/images/team_icons/coybees/coybees_banner.png'),
    'rokerroar': require('../assets/images/team_icons/rokerroar/rokerroar_banner.png'),
    'weareqpr': require('../assets/images/team_icons/weareqpr/weareqpr_banner.png'),
    'wednesdayites': require('../assets/images/team_icons/wednesdayites/wednesdayites_banner.png'),
    'thetractorboys': require('../assets/images/team_icons/thetractorboys/thetractorboys_banner.png'),
    'playuppompey': require('../assets/images/team_icons/playuppompey/playuppompey_banner.png'),
    'bhaseagulls': require('../assets/images/team_icons/bhaseagulls/bhaseagulls_banner.png'),
    'the-gulls': require('../assets/images/team_icons/bhaseagulls/bhaseagulls_banner.png'),
    'lablaugrana': require('../assets/images/team_icons/lablaugrana/lablaugrana_banner.png'),
    'cim-bom': require('../assets/images/team_icons/cim-bom/cim-bom_banner.png'),
    'barca': require('../assets/images/team_icons/barca/barca.png'),
    'hala-madrid': require('../assets/images/team_icons/hala-madrid/hala-madrid.png'),
    'die-bayern': require('../assets/images/team_icons/die-bayern/die-bayern.png'),
    'forza-juve': require('../assets/images/team_icons/forza-juve/forza-juve.png'),
    'allezparis': require('../assets/images/team_icons/allezparis/allezparis.png'),
    'wxmafc': require('../assets/images/team_icons/wxmafc/wxmafc.png'),
    'theherons': require('../assets/images/team_icons/the-herons/the-herons.png'),
    'hatters1885': require("../assets/images/team_icons/hatters1885/Luton Town App Icon.png"),

};

export {LOGO_BANNER};


let CLUBS_MOBILE_BG = {
    '127.0.0.1': 'bg-gunners-mobile',
    'localhost': 'bg-gunners-mobile', // celtics1967
    'cfc-blues': 'bg-cfc-blues-mobile',
    'gunners': 'bg-gunners-mobile',
    'celtic1967': 'bg-celtic1967-mobile',
    'theyellows': 'bg-theyellows-mobile',
    'villatillidie': 'bg-villatillidie-mobile',
    'coywhites': 'bg-coywhites-mobile',
    'coyirons': 'bg-coyirons-mobile',
    'watp': 'bg-watp-mobile',
    'lufcmot': 'bg-lufcmot-mobile',
    'the-toffees': 'bg-the-toffees-mobile',
    'unitedreds': 'bg-unitedreds-mobile',
    'thekop': 'bg-thekop-mobile',
    'toonarmy': 'bg-toonarmy-mobile',
    'coys': 'bg-coys-mobile',
    'mancityzens': 'bg-mancityzens-mobile',
    'comeonleicester': 'bg-comeonleicester-mobile',
    'wearewolves': 'bg-wearewolves-mobile',
    'saints1885': 'bg-saints1885-mobile',
    'greasychipbutty': 'bg-greasychipbutty-mobile',
    'palaceeagles': 'bg-palaceeagles-mobile',
    'coyhorns': 'bg-coyhorns-mobile',
    'citybluebirds': 'bg-citybluebirds-mobile',
    'redandblackarmy': 'bg-redandblackarmy-mobile',
    'the-clarets': 'bg-the-clarets-mobile',
    'the-terriers': 'bg-the-terriers-mobile',
    'foresttilidie': 'bg-foresttilidie-mobile',
    'goonstoke': 'bg-goonstoke-mobile',
    'theteessiders': 'bg-theteessiders-mobile',
    'roverstilidie': 'bg-roverstilidie-mobile',
    'boingboingbaggies': 'bg-boingboingbaggies-mobile',
    'bcfckro': 'bg-bcfckro-mobile',
    'comeonderby': 'bg-comeonderby-mobile',
    'oneteaminbristol': 'bg-oneteaminbristol-mobile',
    'coybees': 'bg-coybees-mobile',
    'rokerroar': 'bg-rokerroar-mobile',
    'weareqpr': 'bg-weareqpr-mobile',
    'wednesdayites': 'bg-wednesdayites-mobile',
    'thetractorboys': 'bg-thetractorboys-mobile',
    'playuppompey': 'bg-playuppompey-mobile',
    'bhaseagulls': 'bg-bhaseagulls-mobile',
    'the-gulls': 'bg-bhaseagulls-mobile',
    'lablaugrana': 'bg-lablaugrana-mobile',
    'cim-bom': 'bg-cim-bom-mobile',
    'barca': 'bg-barca-mobile',
    'hala-madrid': 'bg-hala-madrid-mobile',
    'die-bayern': 'bg-die-bayern-mobile',
    'forza-juve': 'bg-forza-juve-mobile',
    'allezparis': 'bg-allezparis-mobile',
    'wxmafc': 'bg-wxmafc-mobile',
    'theherons': 'bg-theherons-mobile',
    'hatters1885': 'bg-hatters1885-mobile',

};


export {CLUBS_MOBILE_BG};

let LOADING_GIF = {
    'gunners': require("../assets/loader/GunnersLoadingSpinner.gif"),
    'cfc-blues': require("../assets/loader/CFCBluesLoadingSpinner.gif"),
    'celtic1967': require("../assets/loader/CelticLoadingSpinner.gif"),
    'theyellows': require("../assets/loader/theyellows.gif"),
    'coywhites': require("../assets/loader/COYWhitesLoadingSpinner.gif"),
    'villatillidie': require("../assets/loader/VillaTillidieLoadingSpinner.gif"),
    'coyirons': require("../assets/loader/CoyIronsLoadingSpinner.gif"),
    'watp': require("../assets/loader/watp.gif"),
    'lufcmot': require("../assets/loader/lufcmot.gif"),
    'the-toffees': require("../assets/loader/the-toffees.gif"),
    'unitedreds': require("../assets/loader/unitedreds.gif"),
    'thekop': require("../assets/loader/thekop.gif"),
    'toonarmy': require("../assets/loader/toonarmy.gif"),
    'coys': require("../assets/loader/coys.gif"),
    'mancityzens': require("../assets/loader/mancityzens.gif"),
    'comeonleicester': require("../assets/loader/comeonleicester.gif"),
    'wearewolves': require("../assets/loader/wearewolves.gif"),
    'saints1885': require("../assets/loader/saints1885.gif"),
    'greasychipbutty': require('../assets/loader/gcb.gif'),
    'palaceeagles': require('../assets/loader/palaceeagles.gif'),
    'coyhorns': require('../assets/loader/coyhorns.gif'),
    'citybluebirds': require('../assets/loader/citybluebirds.gif'),
    'redandblackarmy': require('../assets/loader/redandblackarmy.gif'),
    'the-clarets': require('../assets/loader/the-clarets.gif'),
    'the-terriers': require('../assets/loader/the-terriers.gif'),
    'foresttilidie': require('../assets/loader/foresttilidie.gif'),
    'goonstoke': require('../assets/loader/goonstoke.gif'),
    'theteessiders': require('../assets/loader/theteessiders.gif'),
    'roverstilidie': require('../assets/loader/roverstilidie.gif'),
    'boingboingbaggies': require('../assets/loader/boingboingbaggies.gif'),
    'bcfckro': require('../assets/loader/bcfckro.gif'),
    'comeonderby': require('../assets/loader/comeonderby.gif'),
    'fanzinesports': require('../assets/loader/fanzinesports.gif'),
    // 'fanzine': require('../assets/loader/fanzinesports.gif'),
    'fanzine': require('../assets/images/corporate/fanzineLoader.gif'),
    'weareswansea': require('../assets/loader/weareswansea.gif'),
    'oneteaminbristol': require('../assets/loader/oneteaminbristol.gif'),
    'coybees': require('../assets/loader/coybees.gif'),
    'rokerroar': require('../assets/loader/rokerroar.gif'),
    'weareqpr': require('../assets/loader/weareqpr.gif'),
    'wednesdayites': require('../assets/loader/wednesdayites.gif'),
    'thetractorboys': require('../assets/loader/thetractorboys.gif'),
    'playuppompey': require('../assets/loader/playuppompey.gif'),
    'bhaseagulls': require('../assets/loader/bhaseagulls.gif'),
    'the-gulls': require('../assets/loader/bhaseagulls.gif'),
    'lablaugrana': require('../assets/loader/lablaugrana.gif'),
    'cim-bom': require('../assets/loader/cim-bom.gif'),
    'barca': require('../assets/loader/cim-bom.gif'),
    'hala-madrid': require('../assets/loader/cim-bom.gif'),
    'die-bayern': require('../assets/loader/cim-bom.gif'),
    'forza-juve': require('../assets/loader/cim-bom.gif'),
    'allezparis': require('../assets/loader/cim-bom.gif'),
    'wxmafc': require('../assets/loader/cim-bom.gif'),
    'theherons': require('../assets/loader/cim-bom.gif'),
    'hatters1885': require("../assets/loader/cim-bom.gif"),

};
export {LOADING_GIF};

let CROWD_FUNDING_IMAGE = {
    // 'localhost': require('../assets/images/new_gunners_logo.png'),
    '127.0.0.1': require('../assets/images/crowd-funding-image/Investor awards GUNNERS.png'),
    'localhost': require('../assets/images/crowd-funding-image/Investor awards (CELTIC).png'), // celtics1967
    'cfc-blues': require('../assets/images/crowd-funding-image/Investor awards (CHELSEA).png'),
    'gunners': require('../assets/images/crowd-funding-image/Investor awards GUNNERS.png'),
    'celtic1967': require('../assets/images/crowd-funding-image/Investor awards (CELTIC).png'),
    'theyellows': require('../assets/images/crowd-funding-image/TheYellows.png'),
    'villatillidie': require('../assets/images/crowd-funding-image/VillaTillIDie Banner.png'),
    'coywhites': require('../assets/images/crowd-funding-image/Investor awards (FULHAM).png'),
    'coyirons': require('../assets/images/crowd-funding-image/COYIrons Banner.png'),
    'watp': require('../assets/images/crowd-funding-image/Investor awards WATP.png'),
    'lufcmot': require('../assets/images/crowd-funding-image/LUFCMOT Banner.png'),
    'the-toffees': require('../assets/images/crowd-funding-image/Investor awards (EVERTON).png'),
    'unitedreds': require('../assets/images/crowd-funding-image/Investor awards (UNITED).png'),
    'thekop': require('../assets/images/crowd-funding-image/TheKop Banner.png'),
    'toonarmy': require('../assets/images/crowd-funding-image/ToonArmy Banner.png'),
    'coys': require('../assets/images/crowd-funding-image/Investor awards (SPURS).png'),
    'mancityzens': require('../assets/images/crowd-funding-image/ManCityzens Banner.png'),
    'comeonleicester': require('../assets/images/crowd-funding-image/ComeOnLeicester Banner.png'),
    'wearewolves': require('../assets/images/crowd-funding-image/Investor awards (WOLVES).png'),
    'saints1885': require('../assets/images/crowd-funding-image/Saints1885.png'),
    'greasychipbutty': require('../assets/images/crowd-funding-image/GreasyChipButty Banner.png'),
    'palaceeagles': require('../assets/images/crowd-funding-image/Investor awards (PALACE).png'),
    'coyhorns': require('../assets/images/crowd-funding-image/Investor awards (Watford).png'),
    'citybluebirds': require('../assets/images/crowd-funding-image/CityBluebirds Banner.png'),
    'redandblackarmy': require('../assets/images/crowd-funding-image/RedAndBlackArmy Banner.png'),
    'the-clarets': require('../assets/images/crowd-funding-image/Investor awards (BURNLEY).png'),
    'the-terriers': require('../assets/images/crowd-funding-image/The-Terriers Banner.png'),
    'foresttilidie': require('../assets/images/crowd-funding-image/ForestTilIDie Banner.png'),
    'goonstoke': require('../assets/images/crowd-funding-image/GoOnStoke Banner.png'),
    'theteessiders': require('../assets/images/crowd-funding-image/TheTeessiders Banner.png'),
    'roverstilidie': require('../assets/images/crowd-funding-image/Investor awards (Blackburn).png'),
    'boingboingbaggies': require('../assets/images/crowd-funding-image/Investor awards (baggies).png'),
    'bcfckro': require('../assets/images/crowd-funding-image/Investor awards (BCFCKRO).png'),
    'comeonderby': require('../assets/images/crowd-funding-image/Investor awards (Derby).png'),
    // 'fanzinesports': require('../assets/images/team_icons/fanzinesports/fanzine_favicon.png'),
    // 'fanzine': require('../assets/images/team_icons/fanzinesports/fanzine_favicon.png'),
    // 'fanzine': require('../assets/images/crowd-funding-image/fanzine.png'),
    'weareswansea': require('../assets/images/crowd-funding-image/WeAreSwansea Banner.png'),
    'fanzine': require('../assets/images/crowd-funding-image/investor rewards Fanzine.png'),
    // 'fanzinesports': require('../assets/images/crowd-funding-image/'),
    'oneteaminbristol': require('../assets/images/crowd-funding-image/Investor awards (bristol).png'),
    'coybees': require('../assets/images/crowd-funding-image/COYBees Banner.png'),
    'rokerroar': require('../assets/images/crowd-funding-image/Investor awards (Roker).png'),
    'weareqpr': require('../assets/images/crowd-funding-image/Investor awards (QPR).png'),
    'wednesdayites': require('../assets/images/crowd-funding-image/Wednesdayites Banner.png'),
    'thetractorboys': require('../assets/images/crowd-funding-image/Investor awards (TractorBoys).png'),
    'playuppompey': require('../assets/images/crowd-funding-image/Investor awards (pompey).png'),
    'bhaseagulls': require('../assets/images/crowd-funding-image/BHASeagulls Banner.png'),
    'the-gulls': require('../assets/images/crowd-funding-image/BHASeagulls Banner.png'),
    // 'lablaugrana': require('../assets/images/crowd-funding-image/fanzine.png'),
    'cim-bom': require('../assets/images/crowd-funding-image/fanzine.png'),
    'barca': require('../assets/images/crowd-funding-image/fanzine.png'),
    'hala-madrid': require('../assets/images/crowd-funding-image/fanzine.png'),
    'die-bayern': require('../assets/images/crowd-funding-image/fanzine.png'),
    'forza-juve': require('../assets/images/crowd-funding-image/fanzine.png'),
    'allezparis': require('../assets/images/crowd-funding-image/fanzine.png'),
    'wxmafc': require('../assets/images/crowd-funding-image/fanzine.png'),
    'theherons': require('../assets/images/crowd-funding-image/fanzine.png'),
    'hatters1885': require("../assets/images/team_icons/hatters1885/Luton Town App Icon.png"),
};

export {CROWD_FUNDING_IMAGE}

let TEAM_NAME = {
    // 'localhost': 'Gunners',
    '127.0.0.1': 'CFC Blues',
    'localhost': 'Celtic1967',
    'cfc-blues': 'CFC Blues',
    'gunners': 'Gunners',
    'celtic1967': 'Celtic1967',
    'theyellows': 'TheYellows',
    'coywhites': 'COYWhites',
    'villatillidie': 'VillaTillidie',
    'coyirons': 'CoyIrons',
    'watp': 'WATP',
    'lufcmot': 'LUFCMOT',
    'the-toffees': 'The Toffees',
    'unitedreds': 'United Reds',
    'thekop': 'The Kop',
    'toonarmy': 'Toon Army',
    'coys': 'COYS',
    'mancityzens': 'ManCityzens',
    'comeonleicester': 'ComeOnLeicester',
    'wearewolves': 'WeAreWolves',
    'saints1885': 'Saints1885',
    'greasychipbutty': 'Greasy Chip Butty',
    'palaceeagles': 'PalaceEagles',
    'coyhorns': 'COYHorns',
    'citybluebirds': 'CityBlueBirds',
    'redandblackarmy': 'RedAndBlackArmy',
    'the-clarets': 'TheClarets',
    'the-terriers': 'TheTerriers',
    'foresttilidie': 'ForestTillIDie',
    'goonstoke': 'GoOnStoke',
    'theteessiders': 'TheTeessiders',
    'roverstilidie': 'RoversTiliDie',
    'boingboingbaggies': 'BoingBoingBaggies',
    'bcfckro': 'BCFCKRO',
    'comeonderby': 'ComeOnDerby',
    'fanzinesports': 'Fanzine',
    'fanzine': 'Fanzine',
    'weareswansea': 'WeAreSwansea',
    'oneteaminbristol': 'OneTeamInBristol',
    'coybees': 'COYBees',
    'rokerroar': 'RokerRoar',
    'weareqpr': 'WeAreQPR',
    'wednesdayites': 'Wednesdayites',
    'thetractorboys': 'TheTractorBoys',
    'playuppompey': 'PlayUpPompey',
    'bhaseagulls': 'BHASeagulls',
    'the-gulls': 'The-Gulls',
    'lablaugrana': 'Lablaugrana',
    'cim-bom': 'Cim-Bom',
    'barca': 'Barcelona',
    'hala-madrid': 'HALA-MADRID',
    'die-bayern': 'DIE-BAYERN',
    'forza-juve': 'FORZA-JUVE',
    'allezparis': 'ALLEZPARIS',
    'wxmafc': 'WREXHAM',
    'theherons': 'Inter Miami'
};

export {TEAM_NAME};

let VIDEO_ADS = {
    // 'localhost': '#e20001', // gunners
    'localhost': '110249', // celtic1967
    '127.0.0.1': '110249',
    'cfc-blues': '110650',
    'gunners': '110249',
    'celtic1967': '110654',
    'theyellows': '110831',
    'villatillidie': '110831',
    'coywhites': '110831',
    'coyirons': '110831',
    'watp': '110831',
    'lufcmot': '110831',
    'the-toffees': '110831',
    'unitedreds': '110653',
    'thekop': '110651',
    'toonarmy': '110655',
    'coys': '110831',
    'mancityzens': '110652',
    'comeonleicester': '110831',
    'wearewolves': '110831',
    'saints1885': '110831',
    'greasychipbutty': '110831',
    'palaceeagles': '110831',
    'coyhorns': '110831',
    'citybluebirds': '110831',
    'redandblackarmy': '110831',
    'the-clarets': '110831',
    'the-terriers': '110831',
    'foresttilidie': '110831',
    'goonstoke': '110831',
    'theteessiders': '110831',
    'roverstilidie': '110831',
    'boingboingbaggies': '110831',
    'bcfckro': '110831',
    'comeonderby': '110831',
    'fanzinesports': '110831',
    'fanzine': '110831',
    'weareswansea': '110831',
    'oneteaminbristol': '110831',
    'coybees': '110831',
    'rokerroar': '110831',
    'weareqpr': '110831',
    'wednesdayites': '110831',
    'thetractorboys': '110831',
    'playuppompey': '110831',
    'bhaseagulls': '110831',
    'the-gulls': '110831',
    'lablaugrana': '110831',
    'cim-bom': '110831',
    'barca': '110831',
    'hala-madrid': '110831',
    'die-bayern': '110831',
    'forza-juve': '110831',
    'allezparis': '110831',
    'wxmafc': '110831',
    'theherons': '110249',
};

export {VIDEO_ADS};

let SKY_ADS = {
    // 'localhost': '#e20001', // gunners
    'localhost': '17934', // celtic1967
    '127.0.0.1': '17934',
    'cfc-blues': '17961',
    'gunners': '17934',
    'celtic1967': '17977',
    'theyellows': '17976',
    'villatillidie': '17952',
    'coywhites': '17964',
    'coyirons': '17974',
    'watp': '17977',
    'lufcmot': '17965',
    'the-toffees': '17963',
    'unitedreds': '17969',
    'thekop': '17967',
    'toonarmy': '17970',
    'coys': '17973',
    'mancityzens': '17968',
    'comeonleicester': '17966',
    'wearewolves': '17975',
    'saints1885': '17972',
    'greasychipbutty': '17934',
    'palaceeagles': '17962',
    'coyhorns': '17976',
    'citybluebirds': '17976',
    'redandblackarmy': '17953',
    'the-clarets': '17976',
    'the-terriers': '17976',
    'foresttilidie': '17971',
    'goonstoke': '17976',
    'theteessiders': '17976',
    'roverstilidie': '17976',
    'boingboingbaggies': '17976',
    'bcfckro': '17976',
    'comeonderby': '17976',
    'fanzinesports': '17934',
    'fanzine': '17934',
    'weareswansea': '17976',
    'oneteaminbristol': '17976',
    'coybees': '17954',
    'rokerroar': '17976',
    'weareqpr': '17976',
    'wednesdayites': '17976',
    'thetractorboys': '17976',
    'playuppompey': '17976',
    'bhaseagulls': '17960',
    'the-gulls': '17960',
    'lablaugrana': '17979',
    'cim-bom': '17979',
    'barca': '17979',
    'hala-madrid': '17979',
    'die-bayern': '17979',
    'forza-juve': '17979',
    'allezparis': '17979',
    'wxmafc': '17979',
    'theherons': '17934',
};

export {SKY_ADS};
