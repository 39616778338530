import React, {useCallback, useEffect, useState} from 'react';
import {get} from '../../Axios/get';
import {FOOTBALL, NBA, NFL, MLB, CRICKET, NCAA} from '../../Axios/EndPoints';
import {useSelector} from "react-redux";
import GoogleAdManager from "../../Components/Ads/GoogleAdManager";
import moment from "moment-timezone";
import {Card} from 'react-bootstrap';
import Loader from "../../Components/Loader/Loader";
import {useParams} from "react-router-dom";
import HomeTopSlider from "./HomeTopSlider";

const SportNewsPage = (props) => {
    const {
        isMobile
    } = props;

    const {sport} = useParams();
    const getFormattedSport = (sport) => {
        switch (sport?.toLowerCase()) {
            case "football":
                return "Football";
            case "nba":
                return "NBA";
            case "nfl":
                return "NFL";  // As per your mapping
            case "mlb":
                return "MLB";
            case "cricket":
                return "Cricket";
            case "ncaa":
                return "NCAAM";  // As per your mapping
            case "womens":
                return "Womens";
            default:
                return "Football";  // Default to Football if no match
        }
    };

    const [selectedSport, setSelectedSport] = useState(getFormattedSport(sport));

    const {football} = useSelector((state) => state);
    const {fanzineTeamsLeagues = []} = football || {};
    const [latestNews, setLatestNews] = useState([]);
    const [sliderData, setSliderData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newsPageLoading, setNewsPageLoading] = useState(false);

    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const sportEndpoints = {
        Football: {endpoint: FOOTBALL.LatestNewsEndPoint, sport: 'football'},
        NBA: {endpoint: NBA.NewsEndPoint, sport: 'nba'},
        NFL: {endpoint: NFL.NewsEndPoint, sport: 'nfl'},
        MLB: {endpoint: MLB.NewsEndPoint, sport: 'mlb'},
        Cricket: {endpoint: CRICKET.NewsEndPoint, sport: 'cricket'},
        NCAAM: {endpoint: NCAA.NewsEndPoint, sport: 'ncaa'},
        Womens: {endpoint: FOOTBALL.LatestNewsEndPoint, sport: 'football'},
    };
    const fetchLatestNews = async (endpoints, page = 1) => {
        if (page === 1) {
            setLoading(true);
        } else {
            setNewsPageLoading(true);
        }

        try {
            let tempNewsData = [];

            const {endpoint, sport} = endpoints;
            const limit = selectedSport === "Womens" ? `?limit=50&page=${page}&teamType=team_women` : `?limit=50&page=${page}`;
            const response = await get(endpoint + limit);
            tempNewsData = response?.data?.map((newsItem) => ({
                ...newsItem,
                sport,
                is_snack: newsItem?.is_snack,
                news_id: newsItem?.news_id,
                slug: newsItem?.slug,
                news_image: newsItem?.image,
                news_title: newsItem?.title,
                news_url: newsItem?.url,
                news_time: moment.tz(newsItem?.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                news_date: moment(newsItem?.datetime).format('D MMM YYYY, H:mm'),
                news_publisher: {
                    publisher_name: newsItem?.publisher.name,
                    publisher_icon: newsItem?.publisher.icon,
                },
            }));

            if (page === 1) {
                // Set the first 6 news items to sliderData
                setSliderData(tempNewsData?.slice(0, 6));
                // Set the remaining news items to latestNews
                setLatestNews(tempNewsData?.slice(6));
            } else {
                // On subsequent pages, append new data to latestNews only
                setLatestNews(prevNews => [...prevNews, ...tempNewsData]);
            }

            setHasMore(tempNewsData?.length > 0);

        } catch (err) {
            console.error(err);
        } finally {
            setNewsPageLoading(false);
            setLoading(false);
        }
    };

    useEffect(() => {
        const endpoint = sportEndpoints[selectedSport];
        fetchLatestNews(endpoint, page);
    }, [selectedSport, page]);

    const handleScroll = useCallback(() => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading || !hasMore) return;
        setPage(prevPage => prevPage + 1);
    }, [loading, hasMore]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);


    const addDefaultSrc = (ev) => {
        ev.target.src = require(
            '../../assets/images/football-shots-you-need-to-know.jpg');
    };
    const renderSmallCard = (imageHeight, news) => {
        return (
            <>
                <Card
                    className={`col mt-2 border-0 ${isMobile ? "bg-light-black":"bg-white"}`}
                    style={{
                        height: isMobile ?
                            ('105px') :
                            ('290px'),
                        width: isMobile ? 'auto' : 'auto',
                        marginLeft: '10px',
                    }}
                >
                    <Card.Body className={`p-0 m-0 d-flex ${isMobile ? "flex-row w-100" : "flex-column"}`}>
                        <Card.Img
                            variant={"top"}
                            className={'rounded-0'}
                            height={isMobile ? "105px" : imageHeight}
                            // width={isMobile ? 'w-30' : 'auto'}
                            src={news && news?.news_image}
                            alt={news && news?.news_title}
                            onError={addDefaultSrc}
                            style={{
                                objectFit: 'cover',
                                width: isMobile ? '40%' : 'auto',
                            }}
                        />

                        <div style={{width: isMobile ? "60%" : "auto"}}
                             className={`d-flex flex-column py-1 text-start gap-1 ${isMobile ? "px-2 ":""}`}>
                            <div className={"d-flex align-items-center justify-content-between"}>
                                <span
                                    className={`jost font-weight-600 ${isMobile ?
                                        'font-14 font-fanzine' :
                                        'font-16'}`}
                                    style={{color: isMobile ? "" : "#AAAAAA"}}
                                    dangerouslySetInnerHTML={{
                                        __html: news && news?.news_publisher?.publisher_name,
                                    }}
                                />
                                <span
                                    className={`jost font-weight-600 ${isMobile ?
                                        'font-14 font-fanzine' :
                                        'font-14'}`}
                                    style={{color:"#AAAAAA"}}

                                >
                                    {news && news?.news_time
                                        ?.replace('minutes', 'mins')
                                        ?.replace('ago', '')
                                        ?.replace('an', '1')
                                        ?.replace('a day', '1 day')}
                                </span>
                            </div>
                            <div className={""}>
                                <span
                                    className={`jost font-weight-500  text-truncate-custom ${isMobile ? "font-13 text-white" : "font-16 text-black"}`}
                                    dangerouslySetInnerHTML={{__html: news && news?.news_title}}
                                />
                            </div>
                        </div>
                    </Card.Body>

                </Card>
            </>
        );
    };

    const renderSports = () => {
        const sportLimit = 8;
        const filteredSports = fanzineTeamsLeagues?.filter(sport => sport?.name === selectedSport);
        return filteredSports?.slice(0, sportLimit).map((sport, index) => (
            <div key={index} className={`${isMobile ? "w-100" : "row mt-4"}`}>
                {
                    latestNews !== undefined && latestNews !== null && latestNews?.map((newsInData, index) => {
                        const adSpace = (index + 1) % 9 === 0;
                        // const sportPath = selectedSport.toLowerCase();
                        const sportPath = newsInData?.sport;

                        return (
                            <div
                                key={index}
                                className={`m-0 ${adSpace ? `col-12 p-1` : 'col-xl-3 col-lg-3 col-md-3 p-1'} col-sm-12 col-xs-12`}
                            >
                                {adSpace ? (
                                    <div className="col-12 p-1 w-100">
                                        <GoogleAdManager isMobile={isMobile}
                                                         id={isMobile ? '1704953268243' : '1704953333715'}/>
                                    </div>
                                ) : (
                                    <a
                                        href={`/${sportPath}/news/${newsInData?.slug}`}
                                        className={'text-decoration-none text-dark'}
                                        target={"_blank"}
                                        rel="noreferrer"
                                    >
                                        {renderSmallCard(!isMobile ? '170px' : '160px', newsInData)}
                                    </a>
                                )}
                            </div>
                        );
                    })
                }
                {
                    newsPageLoading &&
                    <>
                        {/*<div className={"text-center text-white"}>Loading</div>*/}
                        <Loader loading={newsPageLoading}/>
                    </>
                }
            </div>
        ));
    };
    const renderMain = () => {
        return (
            <div
                style={{
                    marginTop: isMobile ? "-7%": ""
                }}
            >
                <div className={`${isMobile ? "bg-black" : "bg-white"}`}>
                    <div className={``}>
                        {
                            sliderData && sliderData?.length > 0 &&
                            <>
                                <HomeTopSlider
                                    isMobile={isMobile}
                                    sliderData={sliderData}
                                />
                            </>
                        }
                    </div>
                    <div className={`container d-flex flex-row align-items-center gap-3 pb-5`}>
                        {
                            loading ?
                                <>
                                    <Loader loading={loading}/>
                                </> :
                                <>
                                    {renderSports()}
                                </>
                        }

                    </div>
                </div>
            </div>

        );
    };

    return renderMain();
};

export default SportNewsPage;